<template>

  <el-row :gutter="10">

    <el-col class="library-view-number-multiple-col" :span="8">
      <div class="target-text"><b>Цель</b>: {{ scale.target }}</div>
    </el-col>

  </el-row>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";

export default {
  name: "library-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        target: null,
        pay_min: null,
        pay_max: null,
      },
    }
  },
  mounted() {

  },
  computed: {},
  methods: {}
}
</script>

<style>
</style>